import { Box, InputLabel, Select as MUISelect, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import { useTheme } from 'styled-components';
import { useHandleUploadFileAws } from '../../../Hooks';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import FullScreenDialog from '../../../OrderingModule/OrderingComponents/FullScreenDialog';
import { RenderAppointmentPdf } from '../components';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import file from '../../../assets/img/icons/AdjuntarRecto.svg';
import download from '../../../assets/img/icons/downloadArrow2.svg';
import add from '../../../assets/img/icons/grayAdd.svg';
import next from '../../../assets/img/icons/ordRightArrow.svg';
import questionBlueClear from '../../../assets/img/icons/questionBlueClear.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';
import { ordCustomSelect, ordCustomSelect3 } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../../components/Popup/customPopup.module.scss';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { Button, Text } from '../../../components/UI/atoms';
import moment from 'moment';
import {
  MAX_SIZE_FILE,
  calculateCollectionOfAdmissionPatient,
  customSwaltAlertAsistencial,
  depositType,
  formatteDocument,
  getEnvs,
  inputMuiGray,
  isEmptyOrUndefined,
  loader,
  onValidFielTypeFile,
} from '../../../helpers';
import {
  formatNumberWithoutDecimals,
  getLocaleSeparators,
} from '../../../helpers/numberFormatting';
import { ServiceOrderReplace } from '../../Orders';
import { RenderRow } from '../../Orders/components';
import { useGetMethod, usePostMethod } from './../../../Hooks/useFetch';
import usePatientAdmissionStore from './../../../Hooks/usePatientAdmissionStore';
import {
  CardCollectionBase,
  CardCollectionDynamic,
  CardTotal,
  ConsentToPrint,
  DrawerAndAlert,
  RescheduleAppointment,
} from './../components';
import RelatedService from '../components/RelatedService';
import { ConditionalMessage, SearchAppointmentCarousel, SearchCardDoctor } from '../../../AppointmentModule/RegisterAppointment/DetailPatient/components';

const localeSeparators = getLocaleSeparators();
const defaultValueSelect = { label: 'Seleccionar...', value: null };
const initialValuesModalSchedule = {
  active: false,
  index: undefined,
  doctor: null,
  doctorOptions: [],
  service: undefined,
  listSchedules: [],
  unique: undefined,
  eventsIndex: undefined
};
const initialValuesModalRelateService = {
  active: false,
  value: undefined,
  service: undefined,
  index: undefined,
  eventIndex: undefined,
  type: undefined
};

export const PatientInfoCollected = () => {
  // envs
  const { BUCKET_NAME } = getEnvs();

  //  ---------------------------Hooks && LocalStorage-------------------------
  const store = useSelector(state => state);
  const siteId = store.userReducer.site;
  const [showModalReschedule, setShowModalReschedule] = useState(false);
  const [rescheduleAppointment, setRescheduleAppointment] = useState({});
  const [cancelAppointment, setCancelAppointment] = useState(null);
  const history = useHistory();
  const { colors } = useTheme();
  const { activeEvent, setActiveEvent, events = [], setEvents, clearStateAdmissionPatient, image } =
    usePatientAdmissionStore();
  const token = store.loginReducer.Authorization;
  const userName = `${store.loginReducer.user_data.first_name} ${store.loginReducer.user_data.second_name}`;
  const userId = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const eaccount = store.loginReducer.currentAccount.id;
  const [valuesPay, setValuesPay] = useState({
    amount: 0,
    discount: 0,
    advance: 0,
    amountTotal: 0,
    amountTotalFixed: 0,
    amountTotalWithoutTotalBalance: 0,
  });
  const [siteResolutions, setSiteResolutions] = useState([]);

  const [stateTotalBalance, setStateTotalBalance] = useState(activeEvent?.patient?.totalBalance);
  const totalBalance = activeEvent?.patient?.totalBalance;
  const isCopaymentEditable = activeEvent?.appContract?.isCopaymentEditable;

  const createdBy = store.loginReducer.user_data.id;
  const [modal, setModal] = useState({ active: false, index: undefined });
  const [modalSchedule, setModalSchedule] = useState(initialValuesModalSchedule);
  const [modalRelateService, setModalRelateService] = useState(initialValuesModalRelateService);
  const [listHoursSelectedActive, setListHoursSelectedActive] = useState([]);
  const [listHoursSelected, setListHoursSelected] = useState([]);

  const [modalCopago, setModalCopago] = useState({
    show: false,
    index: undefined,
    data: {
      userId,
      appId: undefined,
      srvId: undefined,
      fileUrl: undefined,
      fileName: undefined,
      motiveId: undefined,
    },
  });

  const [modalSarlaft, setModalSarlaft] = useState({
    show: false,
    index: undefined,
    data: {
      userId,
      appId: undefined,
      fileUrl: undefined,
      fileName: undefined,
    },
  });

  const [nameTitleFilesUploader, setNameTitleFilesUploader] = useState({});
  const [collectionCrud, setCollectionCrud] = useState({
    isActiveAddNewCollection: true,
    options: [],
  });

  const pdfRef = useRef();
  const [consent, setConsent] = useState(undefined);
  const [isLoadingPdfConsent, setIsLoadingPdfConsent] = useState(undefined);
  const [fullDialogScreen, setFullDialogScreen] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setSiteResolutions(store.userReducer?.resolutions), [siteResolutions]);

  useEffect(() => {
    const newEvents = events?.map((app) => ({ ...app, resolutionId: siteResolutions[0].id }))
    setEvents(newEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteResolutions]);

  // useEffect(() => {
  //   if (events[0]?.service?.children?.length) {
  //     const newEvents = events?.map((app) => {
  //       const updatedChildren = app?.service?.children?.map((child) => ({
  //         ...child,
  //         app,
  //         resolutionId: siteResolutions[0].id
  //       }));
  //       return {
  //         ...app,
  //         service: {
  //           ...app.service,
  //           children: updatedChildren
  //         }
  //       };
  //     });
  //     setEvents(newEvents)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [siteResolutions, events[0]?.service?.children?.length]);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: `consentimiento`,
  });

  //  --------------------------- Petitions -------------------------
  const {
    results: listDepositType,
    load: loaderListDepositType,
    trigger: getListDepositType,
  } = useGetMethod();

  const {
    results: relatedServicesByApp,
    load: loaderRelatedServicesByApp,
    trigger: getRelatedServicesByApp,
  } = useGetMethod();

  const {
    results: appointmentData,
    load: loaderAppointmentData,
    trigger: getAppointmentData,
  } = useGetMethod();

  const { load: loaderListAppointments, trigger: getListAppointments } = useGetMethod();

  const { results: listBank, load: loaderListBank, trigger: getListBank } = useGetMethod();

  const { load: loaderListSupport, trigger: getListSupport } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  const {
    results: listFranchise,
    load: loaderListFranchise,
    trigger: getListFranchise,
  } = useGetMethod();

  const { trigger: sendDataForm, load: loadSendDataForm } = usePostMethod();

  const { trigger: onSubmitCancellationReason, load: loaderOnSubmitCancellationReason } =
    usePostMethod();

  const { results: listCompany, load: loaderListCompany, trigger: getListCompany } = useGetMethod();

  const { trigger: getCashClosingConfig, load: loadCashClosingConfig } = useGetMethod();

  const { isLoading: loadUploadFileAws, trigger: onUploadFileAws } = useHandleUploadFileAws();

  const { load: loaderListConsent, trigger: getListConsent } = useGetMethod();

  const { load: loaderListDoctors, trigger: getListDoctors } = useGetMethod();

  const { load: loaderScheduleDoctor, trigger: getScheduleDoctor } = useGetMethod();

  //  --------------------------- Actions -------------------------
  const onSelectOptionDotors = ({ index, eventsIndex, serviceId, popId, contractId, locationId, service, cclientId, siteId }) => {
    onChangeSelectOfDoctor({
      doctor: {
        label: service.doctorName ?? "Seleccionar...",
        value: service.doctorId ?? null,
      },
      serviceId: service.id,
      popId: popId,
      cityId: locationId,
      contractId: contractId,
      clientId: cclientId,
      modality: 'on_site',
      siteId: siteId,
      date: new Date(),
      selectSch: true,
      indexEvents: eventsIndex,
    });
    getListDoctors({
      token,
      url: '/medical/appointment/medical-by-contract-and-location/',
      objFilters: {
        idAccount,
        popId,
        contractId,
        srvId: serviceId,
        city: locationId,
      },
    }).then(res => {
      if (res?.success) {
        const formatDoctors = res?.results?.map(doctor => ({
          ...doctor,
          label: doctor?.mdName,
          value: doctor.mdId,
        }));
        setModalSchedule(states => ({
          ...states,
          active: true,
          doctorOptions: formatDoctors?.length
            ? [defaultValueSelect, ...formatDoctors]
            : [defaultValueSelect],
          index,
          eventsIndex,
          service,
          unique: index
        }))


      }
    });
  };

  const handleService = (eventIndex, type, service, servicioIndex = null) => {
    const eventsUpdated = [...events];
    if (!Number.isInteger(eventIndex)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Operación Fallida',
        text: 'No se pudo identificar la cita. Por favor, verifica e inténtalo nuevamente.',
        showCancelButton: false,
      });
    }

    if (!Number.isInteger(servicioIndex) && (type === 'remove' || type === 'update')) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Error en la Operación',
        text: 'No se pudo localizar el servicio seleccionado. Verifica la información y vuelve a intentarlo.',
        showCancelButton: false,
      });
    }

    if (eventsUpdated.length && type === 'add') {
      eventsUpdated[eventIndex] = {
        ...eventsUpdated[eventIndex],
        relatedServices: [...(eventsUpdated[eventIndex].relatedServices || []), service],
      };
    } else if (type === 'remove' && servicioIndex !== null) {
      eventsUpdated[eventIndex] = {
        ...eventsUpdated[eventIndex],
        relatedServices: (eventsUpdated[eventIndex].relatedServices || [])?.filter(
          (_, idx) => idx !== servicioIndex
        ),
      };
    } else if (type === 'update' && servicioIndex !== null) {
      eventsUpdated[eventIndex] = {
        ...eventsUpdated[eventIndex],
        relatedServices: (eventsUpdated[eventIndex].relatedServices || [])?.map((s, idx) =>
          idx === servicioIndex ? service : s
        ),
      };
    }

    setEvents(eventsUpdated);
  };
  const onChangeSelectOfDoctor = (
    {
      doctor,
      serviceId,
      popId,
      clientId,
      contractId,
      attGroupId,
      modality,
      cityId,
      siteId,
      date
    }
  ) => {
    getScheduleDoctor({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: clientId,
        idPopulation: popId,
        idService: serviceId,
        city: cityId,
        idDoctor: doctor.value,
        site: siteId,
        dateSearch: moment(date).format('YYYY-MM-DD'),
        days: 3,
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
    }).then(res => {
      if (res?.success) {
        if (!res?.results?.length) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay agendas disponibles',
            showCancelButton: false,
          }).finally(() => {
            setModalSchedule(states => ({ ...states, listSchedules: [], showCardSchedule: false, doctor: defaultValueSelect }));
            onChangeSelectOfDoctor({
              doctor: defaultValueSelect,
              serviceId,
              popId,
              clientId,
              contractId,
              attGroupId,
              modality,
              cityId,
              siteId,
              date,
            });
          });
        }

        const listSchedules = res?.results?.map(available => ({
          ...available,
          schedule: available?.schedule?.map(sch => ({
            ...sch,
            hours: sch?.hours?.map(hour => ({
              ...hour,
              virtualAppointment: hour?.appointment,
            })),
          })),
        }));

        setModalSchedule(states => ({
          ...states,
          listSchedules: listSchedules,
          doctor,
          showCardSchedule: true,
          date: moment(date).format('YYYY-MM-DD')
        }));
      }
    });
  }

  const onToggleDoctor = ({ unique, active, idSchedule, schedules, doctorName, doctorId }) => {
    if (!active) {
      const formatListHoursSelectedActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            ...el,
            active: false,
          };
        }
        return el;
      });

      const formatData = [modalSchedule]?.map(el => {
        const newListSchedulesHours = el?.listSchedulesHours?.map?.(sch => {
          return {
            ...sch,
            hours: sch?.hours?.map(hour => {
              if (
                formatListHoursSelectedActive?.filter(
                  el => el?.hourStart === hour?.hourStart && hour?.date === el?.date && !el?.active,
                )?.length
              ) {
                return {
                  ...hour,
                  appointment: false,
                  selected: false,
                };
              }

              return {
                ...hour,
                locked: hour?.appointment,
              };
            }),
          };
        });

        return {
          ...el,
          doctor: null,
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                sch?.idSchedule === idSchedule ? { ...sch, active } : sch,
              )
              : el?.listSchedules,
          listSchedulesHours: newListSchedulesHours,
        };
      });
      setModalSchedule({ ...formatData[0] });
      setListHoursSelectedActive(formatListHoursSelectedActive);
    } else {
      const formatListHoursSelectedActive = listHoursSelectedActive?.filter(
        el => el?.unique !== unique,
      );
      const formatData = [modalSchedule]?.map(el => {
        const newListSchedulesHours = [];
        el?.listSchedules?.forEach?.(item => {
          item?.schedule?.forEach(sch => {
            if (sch?.idSchedule === idSchedule) {
              newListSchedulesHours.push({
                ...sch,
                hours: sch?.hours?.map(hour => {
                  if (
                    formatListHoursSelectedActive?.filter(
                      el =>
                        el?.hourStart === hour?.hourStart && hour?.date === el?.date && el?.active,
                    )?.length
                  ) {
                    return {
                      ...hour,
                      selected: Boolean(
                        formatListHoursSelectedActive?.filter(
                          element => element?.unique === el?.unique,
                        )?.length,
                      ),
                      appointment: true,
                    };
                  }

                  return {
                    ...hour,
                    locked: hour?.appointment,
                  };
                }),
              });
            }
          });
        });

        return {
          ...el,
          doctor: {
            label: doctorName,
            value: doctorId
          },
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                sch?.idSchedule === idSchedule ? { ...sch, active } : { ...sch, active: false },
              )
              : el?.listSchedules,

          listSchedulesHours:
            el?.unique === unique
              ? newListSchedulesHours?.length
                ? newListSchedulesHours
                : schedules
              : el?.listSchedulesHours,
        };
      });
      setModalSchedule({ ...formatData[0] });
      setListHoursSelectedActive(formatListHoursSelectedActive);
    }
  };

  const onToggleHourSchedule = ({ unique, date, idSchedule, hourStart, hourEnd }) => {
    const hasHours = listHoursSelected?.filter(
      el => el?.date === date && el?.hourStart === hourStart,
    )?.length;

    const formatHours = hasHours
      ? listHoursSelected
      : listHoursSelected?.length
        ? [...listHoursSelected, { date, hourStart, hourEnd, idSchedule, unique }]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

    const hasHoursActives = listHoursSelectedActive?.filter(el => el?.unique === unique)?.length;

    let newActive = [];

    if (!hasHoursActives) {
      newActive = listHoursSelectedActive?.length
        ? [
          ...listHoursSelectedActive,
          { date, hourStart, hourEnd, idSchedule, unique, active: true },
        ]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

      setListHoursSelectedActive(newActive);
    }

    if (hasHoursActives) {
      newActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            date,
            hourStart,
            hourEnd,
            idSchedule,
            unique,
            active: true,
          };
        }
        return el;
      });

      setListHoursSelectedActive(newActive);
    }

    const formatData = [modalSchedule]?.map(el => {
      if (el?.unique === unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === hour?.date &&
                      item?.unique !== unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return {
                  ...hour,
                  selected: false,
                };
              }),
            };
          }),
        };
      }

      if (moment(el?.date)?.format('YYYY-MM-DD') === date && el?.unique !== unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.date === date &&
                      item?.hourStart === hour?.hourStart &&
                      item?.unique === el?.unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.date === date && item?.hourStart === hour?.hourStart,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return hour;
              }),
            };
          }),
        };
      }

      return el;
    });

    setModalSchedule(...formatData);
    setListHoursSelected(formatHours);

  };

  const validateModalSchedule = () => {
    const data = listHoursSelectedActive?.find((obj) => obj.unique === modalSchedule?.unique)
    const schedule = modalSchedule?.listSchedules?.find((obj) => obj.idSchedule === data?.idSchedule)
    if (modalSchedule && !modalSchedule?.doctor) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un médico',
        showCancelButton: false,
      });
    }
    if (data && !data?.idSchedule) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una agenda',
        showCancelButton: false,
      });
    }
    if (data && !data?.idSchedule) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una agenda',
        showCancelButton: false,
      });
    }
    if ((data && !data?.hourEnd || !data?.hourStart)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una hora de atención',
        showCancelButton: false,
      });
    }
    const filter = events?.map((event, index) => {
      if (index === modalSchedule.eventsIndex) {
        return {
          ...event,
          service: {
            ...event.service,
            children: event?.service?.children?.map((service, serIndex) => {
              if (serIndex === modalSchedule.index) {
                return {
                  ...service,
                  doctorId: modalSchedule.doctor.value,
                  doctorName: modalSchedule.doctor.label,
                  scheduleStatus: data?.active,
                  appDatetime: `${data?.date} ${data?.hourStart}`,
                  appDatetimeEnd: `${data?.date} ${data?.hourEnd}`,
                  schId: data?.idSchedule,
                  scheduleName: schedule?.scheduleName
                }
              }
              return service;
            }
            ),
          },
        };
      }
      return event;
    });
    setEvents(filter);
    setListHoursSelected([]);
    setListHoursSelectedActive([]);
    setModalSchedule(initialValuesModalSchedule);
  };
  const printDataInsurance = () => {
    return (
      <>
        <RenderRow
          title={'Aseguradora'}
          subTitle={cancelAppointment?.detailAppointment?.company || '-'}
        />
        <RenderRow
          title={'Contrato'}
          subTitle={cancelAppointment?.detailAppointment?.contractCorporateClient || '-'}
        />
        <RenderRow
          title={'Grupo de ingreso'}
          subTitle={cancelAppointment?.detailAppointment?.crtName || '-'}
        />
        <RenderRow
          title={'Origen'}
          subTitle={cancelAppointment?.detailAppointment?.population || '-'}
        />
      </>
    );
  };

  const printDataCite = () => {
    const date = cancelAppointment?.detailAppointment?.applicationDateApp.split(' ');
    return (
      <>
        <RenderRow
          title={'Sede'}
          subTitle={cancelAppointment?.detailAppointment?.siteName || '-'}
        />
        <RenderRow
          title={'Médico'}
          subTitle={cancelAppointment?.detailAppointment?.nameDoctor || '-'}
        />
        <RenderRow
          title={'Servicio'}
          subTitle={cancelAppointment?.detailAppointment?.serviceName || '-'}
        />
        <RenderRow title={'Fecha'} subTitle={date?.length ? `${date[0]}` : '-'} />
        <RenderRow title={'Hora'} subTitle={date?.length ? `${date[1]} ${date[2]}` : '-'} />
        <RenderRow title={'No. de historia clínica'} subTitle={'N/A'} />
        <RenderRow title={'Diagnóstico'} subTitle={'falta el key en el endpoint'} />
        <RenderRow
          title={'Total'}
          subTitle={
            cancelAppointment?.detailAppointment?.price
              ? formatNumberWithoutDecimals(cancelAppointment?.detailAppointment?.price)
              : '-'
          }
        />
      </>
    );
  };

  const onCloseModal = () => setShowModalReschedule(false);
  const onOpenModal = () => setShowModalReschedule(true);

  const handleRemoveDeposit = depoTypeId => {
    let amountTotal = 0;
    activeEvent?.listDeposits?.forEach(el => {
      if (el?.depoTypeId !== depoTypeId) {
        amountTotal += Number(el?.amount);
      }
    });

    const listDeposits = activeEvent?.listDeposits?.filter(el => el?.depoTypeId !== depoTypeId);

    setActiveEvent({
      ...activeEvent,
      amountTotal,
      listDeposits,
    });

    if (!listDeposits?.length) {
      setCollectionCrud(state => ({
        ...state,
        isActiveAddNewCollection: true,
      }));
    }
  };

  const handleAddDeposit = () => {
    let options = [];
    const deposit = Object.values(activeEvent?.deposit);
    depositType.forEach(el => {
      if (!deposit.includes(el.value)) {
        options.push(el);
      }
    });

    setCollectionCrud(state => ({
      ...state,
      options,
      isActiveAddNewCollection: true,
    }));
  };

  const dataPost = () => {
    let deposits = [];

    let objCollections = [
      totalBalance
        ? {
          amount:
            totalBalance > valuesPay.amountTotalFixed
              ? totalBalance - stateTotalBalance
              : totalBalance,
          depoTypeId: listDepositType?.results?.find(item => item?.depoTypeLabel === 'wallet')
            ?.depoTypeId,
          depoTypeName: listDepositType?.results?.find(item => item?.depoTypeLabel === 'wallet')
            ?.depoTypeName,
          pendingAmount: totalBalance > valuesPay.amountTotalFixed ? stateTotalBalance : 0,
        }
        : undefined,
      activeEvent?.deposits?.depoTypeName ? { ...activeEvent?.deposits } : undefined,
    ];

    let objListDeposits =
      activeEvent?.listDeposits?.map(el => {
        if (el?.depoTypeName?.toLowerCase() === 'efectivo') {
          const donation = Number(el?.donation);
          return {
            appId: activeEvent?.id,
            siteId,
            amount: el?.amount ? Number(el.amount) : 0,
            depoTypeId: el?.depoTypeId,
            franchise: el?.franchise,
            bank: el?.bank,
            referenceNumber: el?.referenceNumber,
            donation: el?.donation,
            pendingAmount: !isNaN(donation) && donation ? el?.pendingAmount - donation : el?.pendingAmount,
            depoTypeName: el?.depoTypeName,
          };
        }

        return {
          appId: activeEvent?.id,
          depoTypeId: el?.depoTypeId,
          depoTypeName: el?.depoTypeName,
          amount: el?.amount ? Number(el.amount) : 0,
          franchise: el?.franchise,
          bank: el?.bank,
          referenceNumber: el?.referenceNumber,
          donation: 0,
          pendingAmount: 0,
        };
      }) || [];

    if (totalBalance > stateTotalBalance) {
      if (activeEvent?.listDeposits?.length || totalBalance) {
        deposits = objListDeposits;
        deposits = deposits.concat(objCollections)?.filter(el => el !== undefined);
      }
    } else {
      deposits = objListDeposits;

      if (activeEvent?.deposits?.depoTypeName) {
        deposits.push(activeEvent?.deposits);
      }
    }

    const dataSend = {
      deposits,
      userId,
      userName,
      isCopaymentEditable,
      modality: 'on_site',
      sitePrefix: activeEvent?.sitePrefix,
      siteId,
      eaccount,
      createdBy,
      appId: activeEvent?.id,
      statusCode: activeEvent?.appStatus,
      totalAmount: valuesPay?.amountTotalFixed,
      companion: activeEvent?.companion,
      patient: { ...activeEvent?.patient, photo: image },
      appsInfo: [
        ...(events?.map((el) => {
          const service = el?.service;
          const resolutionTag = siteResolutions?.find((obj) => obj?.id === el?.resolutionId)?.tag
          return {
            id: el?.id,
            modality: 'on_site',
            siteId: el?.siteId,
            sitePrefix: el?.sitePrefix,
            resolutionId: el?.resolutionId,
            relatedServices: el?.relatedServices,
            ...(resolutionTag === 'fcfiscal' ? {
              resolutionCompanyName: el?.resolutionCompanyName ?? '',
              resolutionRncCode: el?.resolutionRncCode ?? ''
            } : {}),
            services: [
              {
                id: service?.id,
                isCopaymentEditable: activeEvent?.appContract?.isCopaymentEditable || false,
                consentId: service?.consentId,
                hasConsent: service?.hasConsent,
                amount: Number(service?.amount || 0),
                discount: service?.discount,
                cupCode: service?.cupCode,
                name: service?.name,
                authorizationNumber: service?.authorizationNumber,
                supportFiles: service?.files ? Object.values(service.files) : undefined,
              },
            ],
          };
        }) || []),
        ...(events.length > 0
          ? events.flatMap((event) =>
            event.service?.children?.map((service) => {
              const resolutionTag = siteResolutions?.find((obj) => obj?.id === service?.resolutionId)?.tag
              return {
                id: service.appId,
                modality: "on_site",
                siteId: event.siteId,
                sitePrefix: event.sitePrefix,
                resolutionId: service?.resolutionId,
                relatedServices: service?.relatedServices ?? [],
                ...(resolutionTag === 'fcfiscal' ? {
                  resolutionCompanyName: service?.resolutionCompanyName ?? '',
                  resolutionRncCode: service?.resolutionRncCode ?? ''
                } : {}),
                services: [
                  {
                    id: service?.id,
                    amount: Number(service?.amount || 0),
                  },
                ],
                appDatetime: service?.appDatetime,
                appDatetimeEnd: service?.appDatetimeEnd,
                doctorId: service?.doctorId,
                schId: service?.schId,
              }
            }) || []
          )
          : [])
      ],
      insurances: activeEvent?.insurances?.map(el => ({
        ...el,
        insuranceName: listCompany?.results?.find(company => Number(company?.id) === Number(el?.id))
          ?.copyName,
      })),
    };

    return dataSend;
  };

  const handleSaveForm = () => {
    const depositCash = activeEvent?.listDeposits?.find(
      el => el?.depoTypeName?.toLowerCase() === 'efectivo',
    );

    if (events?.some((app) => (!app.resolutionId || Number.isNaN(app.resolutionId)))) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar la resolución por la que desea facturar',
        showCancelButton: false,
      });
    }

    if (events?.some((app) => (!app.resolutionId || Number.isNaN(app.resolutionId)))) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar la resolución por la que desea facturar',
        showCancelButton: false,
      });
    }

    const hasFcfiscalResolution = events?.some((el) => {
      const resolutionTag = siteResolutions?.find((obj) => obj?.id === el?.resolutionId)?.tag;
      return resolutionTag === 'fcfiscal' && (!el.resolutionRncCode || !el.resolutionCompanyName);
    });

    if (hasFcfiscalResolution) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Los campos RNC y Empresa son obligatorios',
        showCancelButton: false,
      });
    }

    const validateEvents = (events) => {
      if (!Array.isArray(events) || events?.length === 0) {
        return {
          status: false
        };
      }

      for (const event of events) {
        if (event.service && Array?.isArray(event?.service?.children)) {
          for (const child of event.service.children) {
            if (!child?.schId || !child?.doctorId) {
              return {
                status: true,
                message: `Debes selecionar una agenda para el servico ${child?.name}`
              };
            }
            if (!child?.resolutionId) {
              return {
                status: true,
                message: `Debes selecionar una resolución de facturación  para el servico ${child?.name}`
              };
            } else if (child?.resolutionId) {
              const resolutionTag = siteResolutions?.find((obj) => obj?.id === child?.resolutionId)?.tag;
              if (resolutionTag === 'fcfiscal' && (!child.resolutionRncCode || !child.resolutionCompanyName)) {
                return {
                  status: true,
                  message: `Los campos RNC y Empresa son obligatorios para el servico ${child?.name}`
                };
              }
            }
          }
        }
      }
      return {
        status: false
      };
    };

    if (validateEvents(events).status) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: validateEvents(events)?.message,
        showCancelButton: false,
      });
    }

    const validation =
      Number(depositCash?.pendingAmount) + totalBalance < Number(depositCash?.donation);

    if (validation) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El valor de la donación no puede ser mayor al valor de la devuelta total',
        showCancelButton: false,
      });
    }

    const listDeposits = activeEvent?.listDeposits || [];
    for (const deposit of listDeposits) {
      if (
        deposit.depoTypeName === 'Transferencia' &&
        (!deposit.bank || !deposit.referenceNumber || !deposit.amount)
      ) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Debe rellenar todos los campos obligatorios.',
          showCancelButton: false,
        });
      }

      if (deposit.depoTypeName === 'Efectivo' && !deposit.amount) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Debe rellenar todos los campos obligatorios.',
          showCancelButton: false,
        });
      }

      if (
        deposit.depoTypeName === 'Tarjeta' &&
        (!deposit.bank || !deposit.referenceNumber || !deposit.amount || !deposit.franchise)
      ) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Debe rellenar todos los campos obligatorios.',
          showCancelButton: false,
        });
      }
    }

    //falta corregir esto cuando sea mas de un servicio
    let message = 'Verifique la información';
    const hasConsent = Object?.keys(events[0]?.service?.files || {})?.includes(
      `Consentimiento_${activeEvent?.service?.consentId}`,
    );
    if (activeEvent?.service?.hasConsent && !hasConsent) {
      message = `El/los servicio/s ${events[0]?.service?.name} no será/n admitido/s de inmediato, esto debido a que hizo falta la firma del consentimiento.`;
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: message,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(rs => {
      if (rs.isConfirmed) {
        sendDataForm({
          url: `/medical/patientAdmission/`,
          token: token,
          method: 'POST',
          body: dataPost(),
          noAlert: true,
          succesAction: response => {
            if (response.status === 200 || response.status === 201) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Creada exitosamente',
                text: response?.message,
                showCancelButton: true,
                confirmButtonText: 'Previsualizar',
                cancelButtonText: 'Cancelar',
              }).then((res) => {
                if (res.isConfirmed) {
                  getCashClosingConfig({
                    url: `/medical/admissions/cashClosingConfig/`,
                    objFilters: { userId, siteId, eaccount },
                    token: token,
                  }).finally(() => {
                    setFullDialogScreen(true)
                  });
                } else {
                  getCashClosingConfig({
                    url: `/medical/admissions/cashClosingConfig/`,
                    objFilters: { userId, siteId, eaccount },
                    token: token,
                  }).finally(() => {
                    history.push('/admision/admitirPacientes');
                    clearStateAdmissionPatient();
                  });
                }
              });
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const onSaveFormCancellationReason = () => {
    const dataUpdate = {
      app_observations: cancelAppointment?.fields?.canDescription,
      canId: cancelAppointment?.fields?.canMotive,
      id: cancelAppointment?.detailAppointment?.idApp,
      idUser: userId,
      status: 'cancelled',
      patient: cancelAppointment?.detailAppointment?.fullNamePatient,
    };

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se anulará el servicio: ${cancelAppointment?.detailAppointment?.serviceName}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(rs => {
      if (rs.isConfirmed) {
        onSubmitCancellationReason({
          url: `/medical/appointment/`,
          token: token,
          noAlert: true,
          method: 'PUT',
          body: dataUpdate,
          doAfterSuccess: response => {
            if (response.status === 200 || response.status === 201) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Anulada exitosamente',
                text: `Se ha anulado el servicio: ${cancelAppointment?.detailAppointment?.serviceName}`,
                showCancelButton: false,
              }).then(() => {
                history.replace('/admision/inicio');
                clearStateAdmissionPatient();
              });
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const onDiscardService = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se descartará el servicio ${activeEvent?.service?.name}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(res => {
      if (res.isConfirmed) {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Descartado exitosamente',
          text: `Se ha descartado el servicio ${activeEvent?.service?.name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).then(() => {
          history.replace('/admision/inicio');
          clearStateAdmissionPatient();
        });
      }
    });
  };

  const getSupport = el => {
    const { appContract, service } = el;

    const hasConsent = activeEvent?.service?.hasConsent;
    const consent = {
      fileExtensions: '.pdf',
      fileName: 'Consentimiento informado',
      supportId: activeEvent?.service.consentId,
      consent: 1,
    };

    getListSupport({
      url: '/medical/admissions/getConfigSupportFiles/',
      token: token,
      objFilters: {
        contractId: appContract?.contractId,
        offeredService: service?.id,
      },
      doAfterSuccess: response => {
        const format = response?.results;

        if (hasConsent) format?.push(consent);

        setModal(state => ({
          ...state,
          unique: el.id,
          action: 'addSupport',
          active: true,
          documents: format,
        }));
      },
      doAfterException: error => {
        if (hasConsent) {
          setModal(state => ({
            ...state,
            action: 'addSupport',
            active: true,
            documents: [consent],
          }));
        } else {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: error.message,
            showCancelButton: false,
          });
        }
      },
    });
  };

  const validateAddServiceRelate = () => {
    if (!modalRelateService.service || !modalRelateService.service.id) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Debe seleccionar un servicio complementario`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
    handleService(
      modalRelateService.eventsIndex,
      modalRelateService.type,
      modalRelateService.service,
      modalRelateService.index
    )
    setModalRelateService(initialValuesModalRelateService);
  };

  const getFilteredOptions = (value) => {
    const selectedIds = events
      ?.flatMap((app) =>
        app?.relatedServices
          ?.map((service) => service.id)
      )
      ?.filter(id => id !== value);

    return relatedServicesByApp?.results
      ?.filter(option => !selectedIds?.includes(option.id))
      .map(option => ({ label: option.name, value: option.id }));
  };

  const onModalRelateService = ({ index, eventsIndex, active, service, value, type }) => {
    setModalRelateService({ index, eventsIndex, active, service, value, type });
  }
  const validateSaveDocuments = () => {
    if (
      !Object.values(nameTitleFilesUploader).length ||
      Object.values(nameTitleFilesUploader)?.some(el => el === '')
    ) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Debe adjuntar todos los documentos`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    customSwaltAlertAsistencial({
      icon: 'success',
      title: 'Cargados exitosamente',
      text: `Se han cargado los documentos de forma exitosa`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });

    setModal(state => ({ ...state, active: false }));
  };

  const validateModalCopago = () => {
    const { motiveId, fileName } = modalCopago.data;

    if (isEmptyOrUndefined(motiveId) || isEmptyOrUndefined(fileName)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Todos los campos son obligatorios',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    sendDataForm({
      url: `/medical/patientAdmission/`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: { ...modalCopago.data },
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Copago anulado exitosamente',
          text: `Se ha anulado el copago del servicio: ${events[modalCopago.index]?.service?.name}`,
          showCancelButton: false,
        });

        onHideModalCopago();

        const format = events?.map(el => {
          if (el?.id === modalCopago?.data?.appId) {
            return {
              ...el,
              copaymentWaived: true,
              service: {
                ...el.service,
                amount: 0,
                discount: 0,
                advance: 0,
              },
            };
          }

          return el;
        });

        setEvents(format);
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const validateModalSarlaft = () => {
    if (!modalSarlaft) return;

    const { fileName } = modalSarlaft.data;

    if (isEmptyOrUndefined(fileName)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Agregué un documento sarlaft valido',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    sendDataForm({
      url: `/medical/admissions/sarlaft/`,
      token: token,
      noAlert: true,
      method: 'POST',
      body: { ...modalSarlaft.data },
      doAfterSuccess: res => {
        if (res?.success) {
          customSwaltAlertAsistencial({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: `Se ha agregado la evidencia sarlaft sin problemas`,
            showCancelButton: false,
          });
          onHideModalSarlaft();
        }
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onHideModalCopago = () => {
    setModalCopago({
      show: false,
      index: undefined,
      data: {
        userId,
        appId: undefined,
        srvId: undefined,
        fileUrl: undefined,
        fileName: undefined,
        motiveId: undefined,
      },
    });
  };

  const onHideModalSarlaft = () => {
    setModalSarlaft({
      show: false,
      index: undefined,
      data: {
        userId,
        appId: undefined,
        fileUrl: undefined,
        fileName: undefined,
      },
    });
  };

  const renderTooltip = props => (
    <Tooltip {...props}>
      <div className={`${tableStyles.darkerGrayText}`}>
        Scanner de carnet, carta, certificado, etc.
      </div>
    </Tooltip>
  );

  //  ---------------------------Effects -------------------------

  React.useEffect(() => {
    if (events?.length) {
      const filterServices = [];
      events?.forEach(el => {
        const relatedServicesAmount = el?.relatedServices?.reduce((acc, current) => {
          return acc + +current?.value
        }, 0)

        const relatedServicesPatientAmount = el?.relatedServices?.reduce((acc, current) => {
          return acc + (current?.patientAmount ? +current.patientAmount : 0)
        }, 0)

        const relatedServicesCompanyAmount = el?.relatedServices?.reduce((acc, current) => {
          return acc + (current?.companyAmount ? +current.companyAmount : 0)
        }, 0)

        const servicesChildrenAmount = el?.service?.children?.reduce((acc, current) => {
          return acc + (current?.amount ? +current?.amount : 0)
        }, 0)
        const servicesChildrenPrice = el?.service?.children?.reduce((acc, current) => {
          return acc + (current?.price ? +current?.price : 0)
        }, 0)
        filterServices.push({
          amount: el?.service?.amount,
          discount: el?.discount,
          advance: el?.service?.advance,
          price: el?.service?.price,
          relatedServicesAmount,
          relatedServicesPatientAmount,
          relatedServicesCompanyAmount,
          servicesChildrenAmount,
          servicesChildrenPrice
        });
      });

      const amountTransfer = activeEvent?.deposits?.amount || 0;
      const newDataValuesPay = calculateCollectionOfAdmissionPatient(
        filterServices,
        totalBalance,
        amountTransfer,
      );

      if (newDataValuesPay.amountTotalWithoutTotalBalance >= totalBalance) {
        setStateTotalBalance(0);
      } else {
        const total = totalBalance - Number(newDataValuesPay.amountTotalWithoutTotalBalance);
        setStateTotalBalance(total);
      }
      setValuesPay(newDataValuesPay);
    }
  }, [activeEvent?.deposits?.amount, events, totalBalance]);

  React.useEffect(() => {
    if (cancelAppointment?.show) {
      getListAppointments({
        url: '/medical/generals/appointments/',
        objFilters: { appId: cancelAppointment?.id, eaccount: idAccount },
        token: token,
        doAfterSuccess: res => {
          const data = res?.results[0];

          if (Object.values(data).length) {
            setCancelAppointment(state => ({
              ...state,
              detailAppointment: data,
            }));
          }
        },
      });

      getListCancellationReason({
        url: '/medical/generals/cancellationReason/',
        token: token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelAppointment?.show]);

  React.useEffect(() => {
    getListCompany({
      url: '/coporateClients/',
      objFilters: { id_account: eaccount },
      token: token,
    });

    getListDepositType({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount },
    });

    getListFranchise({
      url: '/accounting/franchise/',
      token: token,
      objFilters: { eaccount },
    });

    getListBank({
      url: '/admin/bankingEntities',
      token: token,
      objFilters: { eaccount },
    });

    getListCancellationReason({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });

    if (!activeEvent.deposit) {
      setActiveEvent({
        ...activeEvent,
        deposit: { cash: 'cash' },
      });
    }

    if (activeEvent?.listDeposits?.length) {
      setCollectionCrud(state => ({
        ...state,
        isActiveAddNewCollection: false,
      }));
    }

    getListConsent({
      url: '/medical/admissions/consents/',
      objFilters: { status: 1 },
      token: token,
    }).then(res => {
      if (res?.success) {
        const format = res?.results?.find?.(el => el?.id === activeEvent?.service.consentId);
        setConsent(format);
      }
    });

    getAppointmentData({
      url: '/medical/patientAdmission/',
      objFilters: {
        eaccount,
        date: moment(new Date()).format("YYYY-MM-DD"),
        admitted: 0,
        site: activeEvent.siteId,
        createdBy: userId
      },
      token: token
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRelatedServicesByApp({
      url: '/medical/offered-service/related_services',
      objFilters: { srv_id: activeEvent?.service?.id, contract_id: activeEvent?.appContract?.contractId },
      token: token,
    })
  }, [getRelatedServicesByApp, token, activeEvent?.service?.id, activeEvent?.appContract?.contractId])
  // eliminar esto
  useEffect(() => {
    const newEvents = events?.map((app) => ({
      ...app,
      relatedServices: relatedServicesByApp?.results?.filter((obj) => obj?.is_transversal === 0).map((obj) => ({
        ...obj,
        patientAmount: 0,
        companyAmount: Number(obj.value ?? 0)
      }))
    }))
    setEvents(newEvents)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedServicesByApp])

  useEffect(() => {
    const appIds = events?.map(app => app?.id)

    if (appointmentData?.results?.length) {
      const newEvents = appointmentData?.results?.filter(app => appIds.includes(app?.id));

      setEvents(newEvents);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentData])

  // ANCHOR: RETURN COMPONENT
  //  ---------------------------Component -------------------------
  return (
    <>
      {(loaderListDepositType ||
        loaderListAppointments ||
        loaderListBank ||
        loaderListFranchise ||
        loaderListCancellationReason ||
        loaderListSupport ||
        loaderOnSubmitCancellationReason ||
        loaderListCompany ||
        loadCashClosingConfig ||
        loadUploadFileAws ||
        loaderListConsent ||
        isLoadingPdfConsent ||
        loaderRelatedServicesByApp ||
        loaderAppointmentData ||
        loaderListDoctors ||
        loaderScheduleDoctor ||
        loadSendDataForm) &&
        loader}
      {/* ------------ Details ---------------- */}
      <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen}>
        <RenderAppointmentPdf
          pdfParams={[{
            url: `/medical/admissions/service-orders/${activeEvent.id}/payments/`, //ESPERAR URL
            objFilters: {
              eaccount,
              siteId,
              userId,
            },
          }, ...events.map(event => ({
            url: event?.service?.children?.map(app => `/medical/admissions/service-orders/${app.appId}/payments/`), //ESPERAR URL
            objFilters: {
              eaccount,
              siteId,
              userId,
            },
          }))]}
          title={'Detalle facturación de cita'}
          backAction={() => {
            setFullDialogScreen(false);
            history.push('/admision/admitirPacientes');
            clearStateAdmissionPatient();
          }}
          withPagination={true}
        />
      </FullScreenDialog>
      <OrdModal
        hideCancelButton
        show={modalRelateService.active}
        btnYesName='Guardar'
        btnYesEvent={() => validateAddServiceRelate()}
        title='Servicio complementario'
        onHide={() => setModalRelateService(state => ({ ...state, active: false }))}
        size='lg'
      >
        <Box display='grid' columnGap='20px' gridTemplateColumns='1fr 1fr 1fr' >
          <div className='d-flex flex-column'>
            <Text title={'Servicio'} color={colors.gray} fontSize='13px' />
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={getFilteredOptions(modalRelateService.value)}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect3}
              value={{
                label: relatedServicesByApp?.results?.find(el => el?.id === modalRelateService.value)?.name,
                value: relatedServicesByApp?.results?.find(el => el?.id === modalRelateService.value)?.id,
              }}
              onChange={e => {
                const service = relatedServicesByApp?.results?.find(el => el?.id === e?.value)
                setModalRelateService((states) => ({
                  ...states,
                  value: e?.value,
                  service: {
                    ...service,
                    patientAmount: 0,
                    companyAmount: Number(service.value ?? 0)
                  }
                }))
              }
              }
            ></Select>
          </div>
          <div style={{ borderBottom: '1px solid #A2A3AB' }}>
            <Text title={'Valor Paciente'} color={colors.gray} fontSize='10px' />
            <NumberFormat
              disabled={events?.[modalRelateService.eventsIndex]?.copaymentWaived}
              allowNegative={false}
              className={`register-inputs ${tableStyles.darkerBlueText} p-0`}
              placeholder='Ingresa el valor...'
              thousandSeparator={localeSeparators.groupSeparator}
              decimalSeparator={localeSeparators.decimalSeparator}
              isNumericString={true}
              value={modalRelateService?.service?.patientAmount ?? 0}
              format={valueStr => {
                if (!valueStr) return '';
                return formatNumberWithoutDecimals(valueStr);
              }}
              allowLeadingZeros={false}
              style={{
                maxHeight: '32px',
                height: '32px',
                border: 'none',
                fontSize: '16px',
                backgroundColor: 'transparent',
                cursor: 'text',
              }}
              isAllowed={(event) => {
                const { value } = event;
                return Number(value) <= Number(modalRelateService?.service?.value)
              }}
              onValueChange={({ value }) => {
                const patientAmount = value
                const companyAmount = modalRelateService?.service?.value - patientAmount
                setModalRelateService((states) => ({
                  ...states,
                  service: {
                    ...states.service,
                    patientAmount,
                    companyAmount
                  }
                }))
              }}
            />
          </div>
          <div style={{ borderBottom: '1px solid #A2A3AB' }}>
            <Text title={'Valor empresa'} color={colors.gray} fontSize='10px' />
            <NumberFormat
              disabled
              allowNegative={false}
              className={`register-inputs ${tableStyles.darkerBlueText} p-0`}
              placeholder='Ingresa el valor...'
              thousandSeparator={localeSeparators.groupSeparator}
              decimalSeparator={localeSeparators.decimalSeparator}
              isNumericString={true}
              value={modalRelateService?.service?.companyAmount ?? 0}
              format={valueStr => {
                if (!valueStr) return '';
                return formatNumberWithoutDecimals(valueStr);
              }}
              allowLeadingZeros={false}
              style={{
                maxHeight: '32px',
                height: '32px',
                border: 'none',
                fontSize: '16px',
                backgroundColor: 'transparent',
                cursor: 'text',
              }}
            />
          </div>
        </Box>
      </OrdModal >
      <OrdModal
        hideCancelButton
        show={modal.active}
        btnYesName='Guardar'
        btnYesEvent={validateSaveDocuments}
        title={modal.action === 'addSupport' ? 'Cargar documentos' : ''}
        onHide={() => setModal(state => ({ ...state, active: false }))}
        size='lg'
      >
        <Box display='grid'>
          {modal?.documents?.map((document, i) => (
            <Box
              className={modal?.documents?.length === i + 1 ? 'my-3' : 'mt-3'}
              key={document?.supportId}
            >
              <Box display={'flex'} gap={1}>
                <Text title={document?.fileName} required={!document?.consent} />
                {document?.consent ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip className={`${tableStyles.darkerGrayText}`}>Descargar</Tooltip>
                    }
                  >
                    <img
                      src={download}
                      className='pointer'
                      alt='Descargar consentimiento'
                      onClick={() => {
                        setIsLoadingPdfConsent(true);

                        handlePrint();

                        setTimeout(() => {
                          setIsLoadingPdfConsent(false);
                        }, 700);
                      }}
                    />
                  </OverlayTrigger>
                ) : (
                  ''
                )}
              </Box>

              <label
                htmlFor={document?.supportId}
                className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end`}
                style={{
                  border: '1px solid #a2a3ab',
                  borderRadius: '5px',
                  fontSize: '12px',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: '10px',
                  padding: '0.25rem 0.4rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Text
                  title={
                    nameTitleFilesUploader[
                    `${document?.fileName.split(' ')[0]}_${document?.supportId}`
                    ] || 'Cargar documento'
                  }
                  color={colors.gray}
                  fontSize='13px'
                />
                <img src={file} alt='agregar archivo' width='18px' height='18px' />
              </label>

              <input
                id={document?.supportId}
                type='file'
                accept={document?.fileExtensions}
                className='d-none w-100'
                placeholder='Cargar documento'
                onChange={({ target }) => {
                  const file = target.files[0];
                  const documentName = `${document?.fileName.split(' ')[0]}_${document?.supportId}`;

                  const isValidFile = onValidFielTypeFile({ id: document?.supportId });

                  if (file.size > MAX_SIZE_FILE) {
                    return customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: 'Intenta de nuevo',
                      text: 'El archivo excede el tamaño máximo permitido (5MB)',
                      showCancelButton: false,
                    });
                  }

                  if (isValidFile) {
                    onUploadFileAws({
                      bucketName: BUCKET_NAME,
                      body: file,
                      keyBody: `${file.name}`,
                      location: 'support_files_admission/temp/',
                    })?.then(e => {
                      if (e?.success) {
                        const filter = events?.map(state =>
                          state.id === modal.unique
                            ? {
                              ...state,
                              service: {
                                ...state.service,
                                files: {
                                  ...state.service.files,
                                  [documentName]: {
                                    fileName: file?.name,
                                    base64: e?.url,
                                    fileSupportTypeId: document?.supportId,
                                  },
                                },
                              },
                            }
                            : state,
                        );

                        setModal(state => ({
                          ...state,
                          files: { ...state.files, [documentName]: file?.name },
                        }));

                        setEvents(filter);

                        setNameTitleFilesUploader(state => ({
                          ...state,
                          [documentName]: file?.name,
                        }));
                      } else {
                        customSwaltAlertAsistencial({
                          icon: 'warning',
                          title: 'Intenta de nuevo',
                          text: 'Ha ocurrido un error al momento de subir el archivo',
                          showCancelButton: false,
                        });
                      }
                    });
                  }
                }}
              />
            </Box>
          ))}
        </Box>
      </OrdModal>
      <OrdModal
        hideCancelButton
        show={modalSchedule.active}
        btnYesName='Guardar'
        btnYesEvent={() => validateModalSchedule()}
        title={'Seleccionar agenda'}
        onHide={() => setModalSchedule(initialValuesModalSchedule)}
        size="lg"
      >
        <div>
          <div className='d-flex flex-column w-50 mb-4'>
            <Text title={'Médico'} color={colors.gray} fontSize='13px' />
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={modalSchedule.doctorOptions}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect3}
              value={modalSchedule.doctor}
              onChange={e => {
                onChangeSelectOfDoctor({
                  doctor: e,
                  serviceId: modalSchedule?.service?.id,
                  popId: events[modalSchedule?.eventsIndex]?.appContract?.popId,
                  cityId: events[modalSchedule?.eventsIndex]?.cityId,
                  contractId: events[modalSchedule?.eventsIndex]?.appContract?.contractId,
                  clientId: events[modalSchedule?.eventsIndex]?.appContract?.cclientId,
                  modality: 'on_site',
                  siteId: events[modalSchedule?.eventsIndex]?.siteId,
                  date: new Date()
                })
              }
              }

            ></Select>
          </div>
          {modalSchedule?.showCardSchedule ? (
            <>
              <Box
                mt={2}
                display={'grid'}
                gridTemplateColumns={'1.5fr 1fr'}
                alignItems={'flex-start'}
                columnGap={3}
              >
                {/* componente del calendario*/}
                <div style={{
                  maxWidth: '300px',
                }}>

                  {modalSchedule?.listSchedules?.filter(doctor => doctor?.active)?.length ? (
                    <SearchAppointmentCarousel
                      currentDate={modalSchedule.date}
                      schedules={modalSchedule?.listSchedulesHours}
                      onToggleHourSchedule={({ idSchedule, hourStart, hourEnd, date, time }) => {
                        onToggleHourSchedule({
                          date,
                          time,
                          hourStart,
                          hourEnd,
                          idSchedule,
                          unique: modalSchedule.unique,
                        });
                      }}
                    />
                  ) : (
                    <ConditionalMessage key={2} hasSchedules={modalSchedule?.listSchedules?.length} />
                  )}
                </div>

                <div style={{ height: '250px', overflowY: 'auto' }}>
                  {modalSchedule?.listSchedules?.map(schedule => (
                    <SearchCardDoctor
                      active={schedule?.active}
                      key={schedule?.idSchedule}
                      scheduleName={schedule?.scheduleName}
                      doctorName={schedule?.doctor}
                      doctorId={schedule?.idDoctor}
                      doctorImage={schedule?.image}
                      onToggleDoctor={({ doctorName, doctorId }) => {
                        onToggleDoctor({
                          unique: modalSchedule.unique,
                          active: !schedule?.active,
                          idSchedule: schedule?.idSchedule,
                          schedules: schedule?.schedule,
                          doctorName: doctorName,
                          doctorId: doctorId
                        });
                      }}
                    />
                  ))}
                </div>
              </Box>
            </>
          ) : (
            ''
          )}
        </div>

      </OrdModal>

      <OrdModal
        title='Anular copago'
        show={modalCopago.show}
        onHide={onHideModalCopago}
        btnYesName='Anular'
        btnYesEvent={validateModalCopago}
      >
        <Box>
          <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
            &nbsp;Motivo
            <span className={`${tableStyles.ordOrangeText}`}>*</span>
          </Box>

          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={listCancellationReason?.results?.map(el => ({
              label: el?.canDescription,
              value: el?.canId,
            }))}
            className='text-secondary '
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            onChange={({ value }) => {
              setModalCopago(state => ({
                ...state,
                data: {
                  ...state.data,
                  motiveId: value,
                },
              }));
            }}
          />
        </Box>

        <Box my={2}>
          <Box className='d-flex align-items-center gap-2'>
            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
              &nbsp;Evidencia de derecho de anulación
              <span className={`${tableStyles.ordOrangeText}`}>*</span>
            </Box>

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={renderTooltip}
            >
              <img src={questionBlueClear} alt='questionBlueClear' />
            </OverlayTrigger>
          </Box>

          <label
            htmlFor='copagoFile'
            className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end`}
            style={{
              border: '1px solid #a2a3ab',
              borderRadius: '5px',
              fontSize: '12px',
              height: '32px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '10px',
              padding: '0.25rem 0.4rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Text
              title={modalCopago?.data?.fileName || 'Cargar evidencia'}
              color={colors.gray}
              fontSize='13px'
            />
            <img src={file} alt='agregar archivo' width='18px' height='18px' />
          </label>

          <input
            type='file'
            id='copagoFile'
            className='d-none w-100'
            accept='.jpg,.png,.jpeg,.pdf'
            placeholder='Cargar evidencia'
            onChange={({ target }) => {
              const file = target.files[0];
              const fileName = file?.name;
              const isValidFile = onValidFielTypeFile({ id: 'copagoFile' });

              if (file.size > MAX_SIZE_FILE) {
                return customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'El archivo excede el tamaño máximo permitido (5MB)',
                  showCancelButton: false,
                });
              }

              if (isValidFile) {
                onUploadFileAws({
                  bucketName: BUCKET_NAME,
                  body: file,
                  keyBody: fileName,
                  location: 'support_files_admission/temp/',
                }).then(({ success, url }) => {
                  if (success) {
                    setModalCopago(state => ({
                      ...state,
                      data: {
                        ...state.data,
                        fileName: fileName,
                        fileUrl: url,
                      },
                    }));
                  } else {
                    customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: 'Intenta de nuevo',
                      text: 'Ha ocurrido un error al momento de subir el archivo',
                      showCancelButton: false,
                    });
                  }
                });
              }
            }}
          />
        </Box>
      </OrdModal>

      <OrdModal
        title='Agregar evidencia sarlaft'
        show={modalSarlaft.show}
        onHide={onHideModalSarlaft}
        btnYesEvent={validateModalSarlaft}
      >
        <Box my={2}>
          <Box className='d-flex align-items-center gap-2 mb-1'>
            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
              &nbsp;Evidencia de sarlaft
              <span className={`${tableStyles.ordOrangeText}`}>*</span>
            </Box>

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={
                <Tooltip>
                  <div className={`${tableStyles.darkerGrayText}`}>Formato: pdf</div>
                </Tooltip>
              }
            >
              <img src={questionBlueClear} alt='questionBlueClear' />
            </OverlayTrigger>
          </Box>

          <label
            htmlFor='sarlaftFile'
            className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end`}
            style={{
              border: '1px solid #a2a3ab',
              borderRadius: '5px',
              fontSize: '12px',
              height: '32px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '10px',
              padding: '0.25rem 0.4rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Text
              title={modalSarlaft?.data?.fileName || 'Cargar evidencia'}
              color={colors.gray}
              fontSize='13px'
            />
            <img src={file} alt='agregar archivo' width='18px' height='18px' />
          </label>

          <input
            id='sarlaftFile'
            type='file'
            accept='.pdf'
            className='d-none w-100'
            placeholder='Cargar evidencia'
            onChange={({ target }) => {
              const file = target.files[0];
              const fileName = file?.name;
              const ext = file.name.split('.').at(-1);

              if (ext !== 'pdf') {
                return customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'El formato del archivo debe ser PDF',
                  showCancelButton: false,
                });
              }

              if (file.size > MAX_SIZE_FILE) {
                return customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'El archivo excede el tamaño máximo permitido (5MB)',
                  showCancelButton: false,
                });
              }

              onUploadFileAws({
                bucketName: BUCKET_NAME,
                body: file,
                keyBody: fileName,
                location: 'support_files_admission/temp/',
              }).then(({ success, url }) => {
                if (success) {
                  setModalSarlaft(state => ({
                    ...state,
                    data: {
                      ...state.data,
                      fileName: fileName,
                      fileUrl: url,
                    },
                  }));
                } else {
                  customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: 'Ha ocurrido un error al momento de subir el archivo',
                    showCancelButton: false,
                  });
                }
              });
            }}
          />
        </Box>
      </OrdModal>

      <ServiceOrderReplace
        data={[]}
        size={940}
        show={cancelAppointment?.show}
        title={'Solicitud de anulación'}
        subTitle={`
                            ${formatteDocument(cancelAppointment?.patient?.document)} -
                            ${cancelAppointment?.patient?.firstName || ''}
                            ${cancelAppointment?.patient?.otherNames || ''}
                            ${cancelAppointment?.patient?.lastName || ''}
                        `}
        onHide={() => {
          setCancelAppointment(null);
        }}
        btnNoEvent={() => setCancelAppointment(null)}
        dataInsurance={{
          data: printDataInsurance,
          subTitle: 'Datos de aseguradora',
        }}
        dataCite={{
          data: printDataCite,
          subTitle: 'Datos de la cita',
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${tableStyles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={listCancellationReason?.results?.map(el => ({
                  label: el?.canDescription,
                  value: el?.canId,
                }))}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  setCancelAppointment(state => ({
                    ...state,
                    fields: { ...state.fields, canMotive: value },
                  }));
                }}
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${tableStyles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                minLength={5}
                placeholder='Escribir...'
                style={{ minHeight: '265px' }}
                className={`text-secondary ordInputAdmission w-100 p-2`}
                onChange={({ target }) => {
                  setCancelAppointment(state => ({
                    ...state,
                    fields: { ...state.fields, canDescription: target.value },
                  }));
                }}
              />
            </Box>
          </Box>

          <Box
            display={'grid'}
            gridTemplateColumns={'120px 180px'}
            columnGap={2}
            justifyContent={'end'}
          >
            <button
              className={`${tableStyles.btnReplaceCancel}`}
              onClick={() => {
                setCancelAppointment(null);
              }}
            >
              Cancelar
            </button>

            <button
              disabled={
                !(cancelAppointment?.fields?.canDescription && cancelAppointment?.fields?.canMotive)
              }
              className={`${tableStyles.btnReplace} ${tableStyles.btnReplaceHover}`}
              onClick={onSaveFormCancellationReason}
            >
              Solicitar anulación
            </button>
          </Box>
        </Box>
      </ServiceOrderReplace>

      {
        showModalReschedule && (
          <RescheduleAppointment
            token={token}
            userId={userId}
            idAccount={idAccount}
            showModal={showModalReschedule}
            onCloseModal={onCloseModal}
            appointment={rescheduleAppointment}
          />
        )
      }

      <OrdGenericTemplate
        rowStyle={{ alignItems: 'center' }}
        extraHeader={
          <DrawerAndAlert
            patientDocument={activeEvent?.patient?.document}
            patientId={activeEvent?.patient?.id}
          />
        }
        titleSize={6}
        className={`w-90 mt-4 mx-2`}
        showBottomBorder={false}
        title={'Admisión del paciente'}
        showBackArrow
        backArrowAction={() => {
          setActiveEvent({ ...activeEvent, progress: 2 });
          history.push({ pathname: '/admision/admitirPacientes/informacionAdicional' });
        }}
      >
        <ProgressBar bg1 bg2 className={'mt-4 mb-3'} />

        <Box color={colors.ordAquaMarine} className={`fw-bold mt-4 mb-3`}>
          Servicios
        </Box>

        {events?.map((el, i) => (
          <>
            <Box display={'grid'} columnGap='2px' gridTemplateColumns='1fr'>
              <Box key={el?.id} className={`${tableStyles.cardStyles} mb-1`}>
                <Box display={'grid'} columnGap='10px' gridTemplateColumns='1fr 100px'>
                  <Box display={'grid'} gap='10px' gridTemplateColumns='1fr'>
                    <Box
                      display={'grid'}
                      gap='20px'
                      gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
                      alignItems={'start'}
                    >
                      <div>
                        <Text title={'Servicio'} color={colors.gray} fontSize='13px' />
                        <Text
                          title={`${i + 1}. ${el?.service?.name}`}
                          color={colors.darkBlue2}
                          fontWeight='bold'
                        />
                      </div>

                      <div>
                        <Text title={'Aseguradora'} color={colors.gray} fontSize='13px' />
                        <Text title={el?.service?.corporateClient} color={colors.darkBlue2} />
                      </div>

                      <div>
                        <Text title={'Contrato'} color={colors.gray} fontSize='13px' />
                        <Text title={el?.service?.contract} color={colors.darkBlue2} />
                      </div>

                      <div>
                        <Text title={'Grupo de ingreso'} color={colors.gray} fontSize='13px' />
                        <Text
                          title={el?.service?.incomeGroup === null ? '-' : el?.service?.incomeGroup}
                          color={colors.darkBlue2}
                        />
                      </div>

                      <div style={{ borderBottom: '1px solid #A2A3AB' }}>
                        <Text title={'Valor Paciente'} color={colors.gray} fontSize='13px' />
                        <NumberFormat
                          disabled={!isCopaymentEditable || el?.copaymentWaived}
                          allowNegative={false}
                          className={`register-inputs ${tableStyles.darkerBlueText} p-0`}
                          placeholder='Ingresa el valor...'
                          thousandSeparator={localeSeparators.groupSeparator}
                          decimalSeparator={localeSeparators.decimalSeparator}
                          isNumericString={true}
                          value={el?.service?.amount || ''}
                          format={valueStr => {
                            if (!valueStr) return '';

                            return formatNumberWithoutDecimals(valueStr);
                          }}
                          isAllowed={(values) => {
                            const { floatValue } = values

                            const maxValue = Number(el?.service?.price - el?.discount)

                            return floatValue ? floatValue <= maxValue : true

                          }}
                          allowLeadingZeros={false}
                          style={{
                            maxHeight: '32px',
                            height: '32px',
                            border: 'none',
                            fontSize: '16px',
                            backgroundColor: 'transparent',
                            cursor: 'text',
                          }}
                          onValueChange={({ value }) => {
                            if (isCopaymentEditable && !el?.copaymentWaived) {
                              const filter = events?.map(event => {
                                if (event?.id === el?.id) {
                                  return {
                                    ...event,
                                    service: {
                                      ...event.service,
                                      amount: value,
                                    },
                                  };
                                }
                                return event;
                              });

                              setEvents(filter);
                            }
                          }}
                        />
                      </div>
                    </Box>

                    <Box display={'grid'} columnGap='20px' gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'>
                      <div>
                        <Text title={'Médico'} color={colors.gray} fontSize='13px' />
                        <Text title={el?.fullNameDoctor} color={colors.darkBlue2} />
                      </div>

                      <div>
                        <Text title={'Anticipo'} color={colors.gray} fontSize='13px' />
                        <span className={tableStyles.darkerBlueText}>
                          {formatNumberWithoutDecimals(el?.service?.advance || 0)}
                        </span>
                      </div>

                      <div>
                        <Text title={'Descuento'} color={colors.gray} fontSize='13px' />
                        <span className={tableStyles.darkerBlueText}>
                          {formatNumberWithoutDecimals(el?.service?.discount || 0)}
                        </span>
                      </div>

                      <div>
                        <TextField
                          label='No. Autorización'
                          type='text'
                          variant='standard'
                          size='small'
                          sx={inputMuiGray}
                          defaultValue={el?.service?.authorizationNumber}
                          onChange={({ target }) => {
                            const filter = events?.map(state =>
                              state.id === el.id
                                ? {
                                  ...state,
                                  service: { ...state.service, authorizationNumber: target.value },
                                }
                                : state,
                            );

                            setEvents(filter);
                          }}
                        />
                      </div>

                      <div className='mt-1'>
                        <label
                          className={`bg-transparent w-100 ${tableStyles.appInputLine} ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} align-self-end mt-2`}
                          style={{
                            fontSize: '12px',
                            height: '32px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: '10px',
                            padding: '0.25rem 0.4rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          onClick={() => {
                            getSupport(el);

                            setModal(state => ({
                              ...state,
                              index: i,
                            }));

                            const xx = {};

                            Object.entries(events[i]?.service?.files || {})?.forEach(file => {
                              xx[file[0]] = file[1]?.fileName;
                            });

                            setNameTitleFilesUploader(
                              Object.values(events[i]?.service?.files || {})?.length ? xx : {},
                            );
                          }}
                        >
                          <Text
                            title={
                              Object.values(el?.service?.files || {})?.length
                                ? 'Soportes cargados'
                                : 'Cargar soportes'
                            }
                            color={colors.ordAquaMarine}
                            fontSize='13px'
                          />

                          <img
                            src={file}
                            className={tableStyles.darkGrayFilter}
                            alt='agregar archivo'
                            width={8}
                          />
                        </label>
                      </div>
                    </Box>
                  </Box>
                  <Box display='flex' alignItems={'center'} justifyContent='center'>
                    <div
                      className={`${tableStyles.addGrayCircle} d-flex align-items-center justify-content-center`}
                      style={{ width: '40px', height: '40px' }}
                    >
                      <CustomPopupExtend
                        noHover
                        position='right center'
                        className={`align-self-end`}
                        triggerClass={`${tableStyles.hoverPopup} wpx30`}
                        triggerSrc={threeDots}
                        extraButtons={[
                          {
                            text: 'Descartar',
                            class: `
                                                        ${CustomPopupStyles.popUpGeneric}
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                            event: () => {
                              onDiscardService(el?.service?.id);
                            },
                          },
                          {
                            text: 'Reprogramar',
                            class: `
                                                        ${CustomPopupStyles.popUpGeneric}
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                            event: () => {
                              onOpenModal();
                              setRescheduleAppointment(el);
                            },
                          },
                          {
                            text: 'Anular cita',
                            class: `
                                                        ${CustomPopupStyles.popUpGeneric}
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                            event: () => {
                              setCancelAppointment(state => ({
                                ...state,
                                show: true,
                                ...el,
                              }));
                            },
                          },
                          el?.service?.amount
                            ? {
                              text: 'Anular copago',
                              class: `
                                                            ${CustomPopupStyles.popUpGeneric}
                                                            ${tableStyles.ordAquaMarineBgHover}
                                                        `,
                              event: () => {
                                setModalCopago(state => ({
                                  ...state,
                                  show: true,
                                  index: i,
                                  data: {
                                    ...state.data,
                                    appId: el?.id,
                                    srvId: el?.service?.id,
                                  },
                                }));
                              },
                            }
                            : undefined,
                          {
                            text: 'Agregar sarlaft',
                            class: `
                                                        ${CustomPopupStyles.popUpGeneric}
                                                        ${tableStyles.ordAquaMarineBgHover}
                                                    `,
                            event: () => {
                              setModalSarlaft(state => ({
                                ...state,
                                show: true,
                                index: i,
                                data: {
                                  ...state.data,
                                  appId: el?.id,
                                },
                              }));
                            },
                          },
                        ]}
                      ></CustomPopupExtend>
                    </div>
                  </Box>
                  {/* ANCHOR: SELECT RESOLUTION */}
                  <Box
                    className={'mt-4'}
                    display={'grid'}
                    columnGap='20px'
                    gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
                  >
                    <div className='d-flex flex-column'>
                      <InputLabel
                        id='resolution-label-id'
                        style={{ color: colors.ordAquaMarine, fontWeight: "bold" }}
                      >
                        Resolución de facturación <span style={{ color: colors.red }}>*</span>

                      </InputLabel>
                      <MUISelect
                        labelId='resolution-select-id'
                        id='resolution'
                        value={events[i]?.resolutionId ?? ''}
                        variant='standard'
                        onChange={event => {
                          const newEvents = events?.map((app, idx) => {
                            if (idx === i) {
                              return {
                                ...app,
                                resolutionId: event.target.value,
                                resolutionCompanyName: '',
                                resolutionRncCode: ''
                              };
                            }
                            return app
                          })
                          setEvents(newEvents)
                        }}
                      >
                        {siteResolutions &&
                          siteResolutions?.map(resolution => {
                            return (
                              <MenuItem key={resolution.id} value={resolution.id}>
                                {resolution.name}
                              </MenuItem>
                            );
                          })}
                      </MUISelect>
                    </div>
                    {siteResolutions?.find((obj) => obj?.id === el?.resolutionId)?.tag === 'fcfiscal' && (<>
                      <div style={{ marginTop: '0.68rem' }}>
                        <TextField
                          label='RNC'
                          type='text'
                          variant='standard'
                          size='small'
                          sx={inputMuiGray}
                          defaultValue={el?.resolutionRncCode}
                          onChange={({ target }) => {
                            const newEvents = events?.map((app, idx) => {
                              if (idx === i) {
                                return {
                                  ...app,
                                  resolutionRncCode: target.value,
                                };
                              }
                              return app
                            })
                            setEvents(newEvents)
                          }}
                          required
                        />
                      </div>
                      <div style={{ marginTop: '0.68rem' }}>
                        <TextField
                          label='Empresa'
                          type='text'
                          variant='standard'
                          size='small'
                          sx={inputMuiGray}
                          defaultValue={el?.resolutionCompanyName}
                          onChange={({ target }) => {
                            const newEvents = events?.map((app, idx) => {
                              if (idx === i) {
                                return {
                                  ...app,
                                  resolutionCompanyName: target.value,
                                };
                              }
                              return app
                            })
                            setEvents(newEvents)
                          }}
                          required
                        />
                      </div>
                    </>)}

                  </Box>
                </Box>

              </Box>

            </Box>

            {el?.relatedServices?.length > 0 && (
              el?.relatedServices?.map((related_service, index) =>
                <RelatedService
                  key={related_service.id}
                  index={index}
                  appIndex={i}
                  appsData={events}
                  setAppsData={setEvents}
                  data={related_service}
                  serviceOptions={[{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }]}
                  onModalRelateService={() => onModalRelateService({
                    value: related_service?.id,
                    active: true,
                    service: related_service,
                    eventsIndex: i,
                    index,
                    type: 'update'
                  })}
                  handleService={() => handleService(i, 'remove', null, index)
                  }
                />
              )
            )}
            {i === 0 ? <div className='mb-4' style={{ display: 'flex', justifyContent: 'end' }}>
              <button className={`${tableStyles.appButtonHover}`} onClick={() => onModalRelateService(
                {
                  active: true,
                  eventsIndex: i,
                  type: 'add'
                }
              )} disabled={(el?.relatedServices?.length === relatedServicesByApp?.results?.length)}>
                <b
                  className={`${tableStyles.tlnTextGray} ${tableStyles.f14} ${tableStyles.appTextHoverTurquoise}`}
                >
                  &nbsp;Agregar servicio complementario
                </b>
                &nbsp;
                <img
                  alt='addIcon'
                  src={add}
                  height='17px'
                  className={`${tableStyles.AppIconHoverTurquoise}`}
                />
              </button>
            </div> : ''}

            {el?.service?.children?.map((service, s) => (
              <>
                <Box key={service?.id} className={`${tableStyles.cardStyles} mb-4`}>
                  <Box display={'grid'} columnGap='10px' gridTemplateColumns='1fr'>
                    <Box display={'grid'} gap='10px' gridTemplateColumns='1fr'>
                      <Box
                        display={'grid'}
                        gap='20px'
                        gridTemplateColumns='1fr 1fr 1fr 1fr 1fr 100px'
                        alignItems={'start'}
                      >
                        <div>
                          <Text title={'Servicio'} color={colors.gray} fontSize='13px' />
                          <Text
                            title={`${service?.name}`}
                            color={colors.darkBlue2}
                            fontWeight='bold'
                          />
                        </div>

                        <div>
                          <Text title={'Aseguradora'} color={colors.gray} fontSize='13px' />
                          <Text title={service?.corporateClient} color={colors.darkBlue2} />
                        </div>

                        <div>
                          <Text title={'Contrato'} color={colors.gray} fontSize='13px' />
                          <Text title={service?.contract} color={colors.darkBlue2} />
                        </div>

                        <div>
                          <Text title={'Grupo de ingreso'} color={colors.gray} fontSize='13px' />
                          <Text
                            title={service?.incomeGroup === null ? '-' : service?.incomeGroup}
                            color={colors.darkBlue2}
                          />
                        </div>

                        <div style={{ borderBottom: '1px solid #A2A3AB' }}>
                          <Text title={'Valor Paciente'} color={colors.gray} fontSize='13px' />
                          <NumberFormat
                            allowNegative={false}
                            className={`register-inputs ${tableStyles.darkerBlueText} p-0`}
                            placeholder='Ingresa el valor...'
                            thousandSeparator={localeSeparators.groupSeparator}
                            decimalSeparator={localeSeparators.decimalSeparator}
                            isNumericString={true}
                            value={service?.amount || ''}
                            format={valueStr => {
                              if (!valueStr) return '';

                              return formatNumberWithoutDecimals(valueStr);
                            }}
                            allowLeadingZeros={false}
                            style={{
                              maxHeight: '32px',
                              height: '32px',
                              border: 'none',
                              fontSize: '16px',
                              backgroundColor: 'transparent',
                              cursor: 'text',
                            }}
                            isAllowed={(values) => {
                              const { floatValue } = values
                              const maxValue = Number(service?.price)
                              return floatValue ? floatValue <= maxValue : true
                            }}
                            onValueChange={({ value }) => {
                              const filter = events?.map(event => {
                                if (event?.id === el?.id) {
                                  return {
                                    ...event,
                                    service: {
                                      ...event.service,
                                      children: event?.service?.children?.map((service, serIndex) => {
                                        if (serIndex === s) {
                                          return {
                                            ...service,
                                            amount: value
                                          }
                                        }
                                        return service;

                                      }

                                      ),
                                    },
                                  };
                                }
                                return event;
                              });
                              setEvents(filter);
                            }}
                          />
                        </div>
                      </Box>
                      {!!service?.doctorName && !!service?.appDatetime && !!service?.appDatetimeEnd && !!service?.scheduleName &&
                        <Box
                          display={'grid'}
                          gap='20px'
                          gridTemplateColumns='1fr 1fr 1fr 1fr 1fr 100px'
                          alignItems={'start'}
                        >
                          <div>
                            <Text title={'Médico '} color={colors.gray} fontSize='13px' />
                            <Text
                              title={`${service?.doctorName}`}
                              color={colors.darkBlue2}
                              fontWeight='bold'
                            />
                          </div>

                          <div>
                            <Text title={'Hora de inicio'} color={colors.gray} fontSize='13px' />
                            <Text title={moment(service?.appDatetime).format('h:mm a')} color={colors.darkBlue2} />
                          </div>

                          <div>
                            <Text title={'Hora de fin'} color={colors.gray} fontSize='13px' />
                            <Text title={moment(service?.appDatetimeEnd).format('h:mm a')} color={colors.darkBlue2} />
                          </div>

                          <div>
                            <Text title={'Agenda'} color={colors.gray} fontSize='13px' />
                            <Text title={service?.scheduleName} color={colors.darkBlue2} />
                          </div>
                        </Box>
                      }



                      {/* ANCHOR: SELECT RESOLUTION */}
                      <Box
                        className={'mt-4'}
                        display={'grid'}
                        columnGap='20px'
                        gridTemplateColumns={siteResolutions?.find((obj) => obj?.id === el?.service.children[s]?.resolutionId)?.tag === 'fcfiscal' ? '1fr 1fr 1fr 2.5fr' : '1fr 4.7fr'}
                      >

                        <div className='d-flex flex-column'>
                          <InputLabel
                            id='resolution-label-id'
                            style={{ color: colors.ordAquaMarine, fontWeight: "bold" }}
                          >
                            Resolución de facturación <span style={{ color: colors.red }}>*</span>

                          </InputLabel>
                          <MUISelect
                            labelId='resolution-select-id'
                            id='resolution'
                            value={events[i]?.service.children[s]?.resolutionId ?? ''}
                            variant='standard'
                            onChange={e => {
                              const filter = events?.map((event, index) => {
                                if (index === i) {
                                  return {
                                    ...event,
                                    service: {
                                      ...event.service,
                                      children: event?.service?.children?.map((service, serIndex) => {
                                        if (serIndex === s) {
                                          return {
                                            ...service,
                                            resolutionId: e.target.value,
                                            resolutionCompanyName: '',
                                            resolutionRncCode: ''
                                          }
                                        }
                                        return service;
                                      }
                                      ),
                                    },
                                  };
                                }
                                return event;
                              });
                              setEvents(filter);
                            }}
                          >
                            {siteResolutions &&
                              siteResolutions?.map(resolution => {
                                return (
                                  <MenuItem key={resolution.id} value={resolution.id}>
                                    {resolution.name}
                                  </MenuItem>
                                );
                              })}
                          </MUISelect>
                        </div>
                        {siteResolutions?.find((obj) => obj?.id === el?.service.children[s]?.resolutionId)?.tag === 'fcfiscal' && (<>
                          <div style={{ marginTop: '0.6rem' }}>
                            <TextField
                              label='RNC'
                              type='text'
                              variant='standard'
                              size='small'
                              sx={inputMuiGray}
                              defaultValue={el?.resolutionRncCode}
                              onChange={({ target }) => {
                                const filter = events?.map((event, index) => {
                                  if (index === i) {
                                    return {
                                      ...event,
                                      service: {
                                        ...event.service,
                                        children: event?.service?.children?.map((service, serIndex) => {
                                          if (serIndex === s) {
                                            return {
                                              ...service,
                                              resolutionRncCode: target.value
                                            }
                                          }
                                          return service;
                                        }
                                        ),
                                      },
                                    };
                                  }
                                  return event;
                                });
                                setEvents(filter);
                              }}
                              required
                            />
                          </div>
                          <div style={{ marginTop: '0.6rem' }}>
                            <TextField
                              label='Empresa'
                              type='text'
                              variant='standard'
                              size='small'
                              sx={inputMuiGray}
                              defaultValue={el?.resolutionCompanyName}
                              onChange={({ target }) => {
                                const filter = events?.map((event, index) => {
                                  if (index === i) {
                                    return {
                                      ...event,
                                      service: {
                                        ...event.service,
                                        children: event?.service?.children?.map((service, serIndex) => {
                                          if (serIndex === s) {
                                            return {
                                              ...service,
                                              resolutionCompanyName: target.value
                                            }
                                          }
                                          return service;
                                        }
                                        ),
                                      },
                                    };
                                  }
                                  return event;
                                });
                                setEvents(filter);
                              }}
                              required
                            />
                          </div>
                        </>)}
                        <div style={
                          {
                            display: 'flex',
                            alignItems: 'end',
                            justifyContent: 'end'
                          }
                        }
                        >
                          <Button
                            className={`btn d-flex gap-2 justify-content-center align-items-center ${tableStyles.btnIconWhiteHover}`}
                            width='200px'
                            padding='8px'
                            fontSize='14px'
                            height='35px'
                            border={`2px solid ${colors.ordAquaMarine}`}
                            backgroundColor={'white'}
                            backgroundColorHover={colors.ordAquaMarine}
                            colorHover={'white'}
                            color={colors.ordAquaMarine}
                            onClick={() =>
                              onSelectOptionDotors({
                                index: s,
                                eventsIndex: i,
                                popId: el?.appContract?.popId,
                                service,
                                serviceId: service?.id,
                                locationId: el?.cityId,
                                contractId: el?.appContract?.contractId,
                                cclientId: el?.appContract?.cclientId,
                                siteId: el?.siteId
                              })
                            }
                          >
                            &nbsp;{!!service.schId && !!service.doctorId ? 'Editar agenda' : 'Seleccionar agenda'}
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Box>

                </Box >

                {service?.relatedServices?.length > 0 && (
                  service?.relatedServices?.map((related_service, index) =>
                    <RelatedService
                      key={related_service.id}
                      index={index}
                      appIndex={i}
                      appsData={events}
                      setAppsData={setEvents}
                      data={related_service}
                    />
                  )
                )}

              </>

            ))}
          </>

        ))}


        <CardTotal
          totalBalance={stateTotalBalance}
          valuesPay={{ ...valuesPay, amountTotal: valuesPay.amountTotalFixed }}
        />

        <Row>
          <Form.Label className={`m-0 ${tableStyles.tlnTextPrimary} ${tableStyles.f14}`}>
            <b className='text-start'>&nbsp;Recaudo</b>
          </Form.Label>
        </Row>

        {totalBalance > stateTotalBalance ? (
          <CardCollectionDynamic
            disabledFields={{ amount: true }}
            data={{
              image: 'cash',
              depoTypeName: 'Bolsillo',
              haveFranchise: false,
              amount:
                totalBalance < valuesPay?.amountTotalWithoutTotalBalance
                  ? totalBalance
                  : valuesPay?.amountTotalWithoutTotalBalance,
            }}
          />
        ) : null}

        {activeEvent?.deposits?.depoTypeName ? (
          <CardCollectionDynamic
            disabledFields={{ amount: true, referenceNumber: true, franchise: true, bank: true }}
            listFranchise={listFranchise}
            listBank={listBank}
            data={{
              image: 'transfer',
              depoTypeName: activeEvent?.deposits?.depoTypeName,
              amount: 0,

              referenceNumber: activeEvent?.deposits?.referenceNumber,
              haveReference: true,

              franchise: activeEvent?.deposits?.franchise,
              franchiseName: listFranchise?.results?.find(
                item => item?.fraId === activeEvent?.deposits?.franchise,
              )?.fraName,
              haveFranchise: !listDepositType?.results?.filter(item => item?.image === 'cardCredit')
                ?.length,

              haveBank: true,
              bankName: listBank?.results?.find(item => item?.id === activeEvent?.deposits?.bank)
                ?.description,
              bank: activeEvent?.deposits?.bank,
            }}
          />
        ) : null}

        {activeEvent?.listDeposits?.map((deposit, key) => (
          <CardCollectionDynamic
            key={key}
            data={deposit}
            handleRemoveDeposit={handleRemoveDeposit}
            activeEvent={activeEvent}
            setActiveEvent={setActiveEvent}
            totalAmount={valuesPay.amountTotal}
            listFranchise={listFranchise}
            listBank={listBank}
            valuesPay={valuesPay}
            isAdmission
          />
        ))}

        {collectionCrud?.isActiveAddNewCollection && valuesPay?.amountTotal ? (
          <CardCollectionBase
            dataOptions={activeEvent?.listDeposits?.map(el => el?.depoTypeId) || []}
            hasDelete={!activeEvent?.listDeposits?.length === 0}
            options={listDepositType?.results
              ?.filter(
                item =>
                  item?.depoTypeLabel !== 'wallet' &&
                  !item?.depoTypeLabel?.endsWith('_note') &&
                  (activeEvent?.deposits?.depoTypeName ? item?.depoTypeLabel !== 'transfer' : true),
              )
              ?.map(el => ({ label: el?.depoTypeName, value: el?.depoTypeId, image: el?.image }))}
            onChange={({ value }) => {
              const activeDepositType = listDepositType?.results?.find(
                el => el?.depoTypeId === value,
              );
              const listDeposits = activeEvent?.listDeposits?.length
                ? [...activeEvent.listDeposits, activeDepositType]
                : [activeDepositType];
              const isExistCash = activeEvent?.listDeposits?.filter(
                el => el?.depoTypeLabel === 'EFECTIVO' && el?.isEditable === 0,
              );

              if (isExistCash?.length) {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'No puedes agregar dos recaudos de tipo <b>efectivo</b>',
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });

                setCollectionCrud(state => ({
                  ...state,
                  isActiveAddNewCollection: false,
                }));

                return;
              } else {
                setActiveEvent({
                  ...activeEvent,
                  listDeposits,
                });

                setCollectionCrud(state => ({
                  ...state,
                  isActiveAddNewCollection: false,
                }));
              }
            }}
          />
        ) : (
          ''
        )}

        <div className='d-flex' style={{ maxWidth: '1070px' }}>
          {!collectionCrud?.isActiveAddNewCollection &&
            activeEvent?.listDeposits?.length <
            listDepositType?.results?.filter(
              item =>
                item?.depoTypeLabel !== 'wallet' &&
                (activeEvent?.deposits?.depoTypeName ? item?.depoTypeLabel !== 'transfer' : true),
            ).length ? (
            <button className={`${tableStyles.appButtonHover}`} onClick={handleAddDeposit}>
              <b
                className={`${tableStyles.tlnTextGray} ${tableStyles.f14} ${tableStyles.appTextHoverTurquoise}`}
              >
                &nbsp;Agregar otro recaudo
              </b>
              &nbsp;
              <img
                alt='addIcon'
                src={add}
                height='17px'
                className={`${tableStyles.AppIconHoverTurquoise}`}
              />
            </button>
          ) : (
            ''
          )}

          <div
            className={`ms-auto fw-bold gap-5 d-flex ${!valuesPay.amountTotal
              ? tableStyles.darkerBlueText
              : valuesPay.amountTotal - (activeEvent?.amountTotal || 0) <= 0
                ? tableStyles.darkerBlueText
                : tableStyles.orangeLightText
              }`}
          >
            <span>Faltante</span>
            <span>
              {formatNumberWithoutDecimals(
                !valuesPay.amountTotal
                  ? 0
                  : valuesPay.amountTotal - (activeEvent?.amountTotal || 0) <= 0
                    ? 0
                    : valuesPay.amountTotal - (activeEvent?.amountTotal || 0),
              )}
            </span>
          </div>
        </div>

        <Box className='d-flex justify-content-end my-5 gap-4'>
          <Button
            className={`btn d-flex gap-2 justify-content-center align-items-center ${tableStyles.btnIconWhiteHover}`}
            width='100px'
            padding='8px'
            fontSize='14px'
            border={`2px solid ${colors.ordAquaMarine}`}
            backgroundColor={'white'}
            backgroundColorHover={colors.ordAquaMarine}
            colorHover={'white'}
            color={colors.ordAquaMarine}
            onClick={() => {
              setActiveEvent({ ...activeEvent, progress: 2 });
              history.push({ pathname: '/admision/admitirPacientes/informacionAdicional' });
            }}
          >
            <img
              style={{ transform: 'rotate(180deg)' }}
              className={`${tableStyles.svgTurquoiseFilter}`}
              src={next}
              alt='next'
            />
            Atrás
          </Button>

          <Button
            className={`btn d-flex gap-2 justify-content-center align-items-center`}
            disabled={(valuesPay?.amountTotal > activeEvent?.amountTotal || !events?.every(app => app.resolutionId))}
            width='130px'
            padding='8px'
            fontSize='14px'
            border='none'
            backgroundColor={colors.ordAquaMarine}
            backgroundColorHover={colors.ordAquaMarine + '90'}
            onClick={handleSaveForm}
          >
            Guardar
            <img className={`${tableStyles.iconWhite}`} src={next} alt='next' />
          </Button>
        </Box>
      </OrdGenericTemplate >

      <div style={{ display: 'none' }}>
        <ConsentToPrint pdfRef={pdfRef} {...consent} />
      </div>
    </>
  );
};
